import { Navigate, useLocation, useSubmission } from '@solidjs/router';
import { Form, Button, Errors, HiddenFields } from '@troon/ui';
import { createEffect, Switch, Match } from 'solid-js';
import { gql, mutationAction, useMutation } from '../../../graphql';
import { useAuthStore } from '../flow';

export function FirstLogin() {
	const [store, setStore] = useAuthStore();
	const location = useLocation();

	const resetPasswordAction = useMutation(resetPassword);
	const resetSubmission = useSubmission(resetPasswordAction);

	createEffect(() => {
		if (resetSubmission.result) {
			setStore({ flow: 'forgotPassword', step: '/auth/reset' });
			resetSubmission.clear();
		}
	});

	return (
		<Switch>
			<Match when={!store.data.email}>
				<Navigate href={`/auth${location.search}`} />
			</Match>
			<Match when={true}>
				<Form document={forgotPasswordMutation} action={resetPasswordAction} method="post" suppressRequired>
					<HiddenFields data={{ email: store.data.email, redirect: store.redirect }} />

					<p>Your password has expired. Please reset it to continue.</p>

					<Errors />

					<Button disabled={resetSubmission.pending} type="submit">
						Reset password
					</Button>
				</Form>
			</Match>
		</Switch>
	);
}

const forgotPasswordMutation = gql(`
mutation forgotPassword($email: String!, $redirect: String) {
	forgotPassword(email: $email, redirectUrl: $redirect) {
		medium
		destination
	}
}`);

const resetPassword = mutationAction(forgotPasswordMutation, {
	track: {
		event: 'passwordResetCode',
	},
});

import { Navigate, useAction, useLocation, useSubmission } from '@solidjs/router';
import { Form, Input, TextField, Label, Button, Errors, ActivityIndicator, FieldDescription } from '@troon/ui';
import { Show, createEffect, Switch, Match } from 'solid-js';
import { gql, mutationAction, useMutation } from '../../../graphql';
import { useAuthStore } from '../flow';

type Props = {
	onComplete?: () => void;
};

export function Login(props: Props) {
	const [store, setStore] = useAuthStore();
	const location = useLocation();

	const action = useMutation(
		login(async () => {
			if (props.onComplete) {
				props.onComplete();
			}
		}),
	);

	const magic = useMutation(magicLink);
	const magicSubmision = useSubmission(magic);

	const resetPasswordAction = useMutation(resetPassword);
	const handleReset = useAction(resetPasswordAction);
	const resetSubmission = useSubmission(resetPasswordAction);

	createEffect(() => {
		if (resetSubmission.result) {
			setStore({ flow: 'forgotPassword', step: '/auth/reset' });
			resetSubmission.clear();
		}
	});

	createEffect(() => {
		if (magicSubmision.result) {
			setStore({ flow: 'magicLink', step: '/auth/magic-link' });
			magicSubmision.clear();
		}
	});

	return (
		<Switch>
			<Match when={!store.data.email}>
				<Navigate href={`/auth${location.search}`} />
			</Match>
			<Match when={true}>
				<Form document={loginQuery} action={action} method="post" suppressRequired>
					<input name="email" type="hidden" value={store.data.email} />

					<TextField name="password">
						<Label>Password</Label>
						<Input type="password" value={store.data.password} />
						<FieldDescription>
							<p class="flex flex-row items-center text-sm">
								Forgot your password?{' '}
								<Button
									class="inline-flex max-w-min shrink text-nowrap p-px font-normal normal-case"
									appearance="transparent"
									type="button"
									onClick={(e) => {
										e.preventDefault();
										e.stopPropagation();
										const data = new FormData();
										data.set('email', store.data.email!);
										data.set('redirect', store.redirect);
										handleReset(data);
									}}
								>
									Reset it now
								</Button>
								.
								<Show when={resetSubmission.pending}>
									<ActivityIndicator class="ms-2 size-4 text-brand" />
								</Show>
							</p>
						</FieldDescription>
					</TextField>

					<Errors />

					<Button disabled={resetSubmission.pending} type="submit">
						Log in
					</Button>
				</Form>

				<p class="my-6 flex items-center justify-stretch gap-4">
					<span class="h-px shrink-0 grow bg-neutral" />
					<span class="shrink grow-0">or</span>
					<span class="h-px shrink-0 grow bg-neutral" />
				</p>

				<Form action={magic} document={magicLinkMutation}>
					<input name="email" type="hidden" value={store.data.email} />
					<input name="redirect" type="hidden" value={store.redirect} />
					<Button type="submit" appearance="secondary">
						Log in without password
					</Button>
					<p class="text-center text-sm">We’ll send you an email to log in instantly.</p>
				</Form>
			</Match>
		</Switch>
	);
}

export const loginQuery = gql(`
	mutation loginV2($email: String!, $password: String!) {
		login: loginV2(input: { email: $email, password: $password, syncInBackground: true }) {
			user {
				email
			}
		}
	}
`);

const login = (onSuccess: () => Promise<void>) =>
	mutationAction(loginQuery, {
		revalidates: undefined,
		onSuccess,
		track: {
			event: 'login',
			transform: (data) => ({ email: data.get('email') as string }),
		},
	});

const forgotPasswordMutation = gql(`
mutation forgotPassword($email: String!, $redirect: String) {
	forgotPassword(email: $email, redirectUrl: $redirect) {
		medium
		destination
	}
}`);

const resetPassword = mutationAction(forgotPasswordMutation, {
	track: {
		event: 'passwordResetCode',
	},
});

const magicLinkMutation = gql(`
mutation emailMagicLink($email: String!, $redirect: String) {
	emailMagicLink(email: $email, redirectUrl: $redirect) {
		ok
	}
}`);

const magicLink = mutationAction(magicLinkMutation, {
	track: {
		event: 'emailMagicLink',
	},
});

import { children, createMemo, Show } from 'solid-js';
import { flows, useAuthStore } from './flow';
import type { Component } from 'solid-js';

type StepProps = {
	header?: Component;
	heading?: string | Component;
	description?: Component;
};

export function StepHeader(props: StepProps) {
	const header = children(() => props.header && props.header({}));
	return (
		<Show when={header()} fallback={<StepHeaderDefault heading={props.heading} description={props.description} />}>
			{header()}
		</Show>
	);
}

function StepHeaderDefault(props: Omit<StepProps, 'header'>) {
	const [store] = useAuthStore();

	const fallbackDescription = createMemo(() => {
		return typeof flows[store.flow][store.step]?.description !== 'undefined'
			? // @ts-expect-error correctly narrowed above
				flows[store.flow][store.step]!.description()
			: null;
	});

	const description = children(() => props.description && props.description({}));

	return (
		<div class="flex flex-col gap-4">
			<Show when={props.heading ?? flows[store.flow][store.step]?.heading}>
				{(heading) => {
					return (
						<h2 class="text-3xl font-semibold sm:text-4xl">
							{/* @ts-expect-error components are executed inline */}
							{heading()}
						</h2>
					);
				}}
			</Show>

			<Show when={description()} fallback={fallbackDescription()}>
				{description()}
			</Show>
		</div>
	);
}

import { Navigate, useLocation } from '@solidjs/router';
import { Form, Input, TextField, Label, Button, Errors } from '@troon/ui';
import { Switch, Match } from 'solid-js';
import { gql, mutationAction, useMutation } from '../../../graphql';
import { useAuthStore } from '../flow';

type Props = {
	onComplete?: () => void;
};

export function MagicLink(props: Props) {
	const [store] = useAuthStore();
	const location = useLocation();

	const action = useMutation(
		makeLoginMutation(async () => {
			if (props.onComplete) {
				props.onComplete();
			}
		}),
	);

	return (
		<Switch>
			<Match when={!store.data.email}>
				<Navigate href={`/auth${location.search}`} />
			</Match>
			<Match when={true}>
				<Form document={magicLinkMutation} action={action} method="post" suppressRequired>
					<input name="email" type="hidden" value={store.data.email} />

					<TextField name="confirmationCode">
						<Label>Code</Label>
						<Input value={store.data.confirmationCode} />
					</TextField>

					<Errors />

					<Button type="submit">Log in</Button>
				</Form>
			</Match>
		</Switch>
	);
}

const magicLinkMutation = gql(`
  mutation confirmEmailMagicLink($email: String!, $confirmationCode: String!) {
    confirmEmailMagicLink(email: $email, confirmationCode: $confirmationCode) {
      user {
        email
      }
    }
  }`);

const makeLoginMutation = (onSuccess: () => Promise<void>) =>
	mutationAction(magicLinkMutation, {
		revalidates: undefined,
		onSuccess,
		track: [
			{
				event: 'login',
				transform: (data) => ({ email: data.get('email') as string }),
			},
			{ event: 'confirmEmailMagicLink' },
		],
	});
